import * as tracks from './track-names.js'; 
const seasonSetups = [
    {
        ...tracks.NURBURGRING_COMBINED_24H,
        "weekStart": "2024-09-10",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.LAGUNA_SECA,
        "weekStart": "2024-09-17",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.MID_OHIO,
        "weekStart": "2024-09-24",
        "notes": [
            "60 minute endurance round",
            "Pit stop may be required"
        ],
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.DAYTONA_ROAD,
        "weekStart": "2024-10-01",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.ROAD_AMERICA,
        "weekStart": "2024-10-08",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.RED_BULL_RING,
        "weekStart": "2024-10-15",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.ZOLDER,
        "weekStart": "2024-10-22",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.JEREZ_MOTO,
        "weekStart": "2024-10-29",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.SACHSENRING,
        "weekStart": "2024-11-05",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.WATKINS_GLEN_CLASSIC_BOOT,
        "weekStart": "2024-11-12",
        "notes": [
            "60 minute endurance round",
            "Pit stop may be required"
        ],
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.VIR,
        "weekStart": "2024-11-19",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.SEBRING,
        "weekStart": "2024-11-26",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
    {
        ...tracks.DONINGTON_PARK,
        "weekStart": "2024-12-03",
        "audi90gto": [
        ],
        "nissangtpzxt": [
        ]
    },
]

export default seasonSetups